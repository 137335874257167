<template>
  <div>
    <nav-bar />
    <div class="prowrap">
      <div class="promain">
        <div class="myInfo">
          <h3><span>我的推广等级：</span>
            <img v-if="userInfo.promotion_group_name === '普通推广'" src="../../assets/images/lev1.png" class="levIcon"/>
            <img v-if="userInfo.promotion_group_name === '白银推广'" src="../../assets/images/lev2.png" class="levIcon"/>
            <img v-if="userInfo.promotion_group_name === '黄金推广'" src="../../assets/images/lev3.png" class="levIcon"/>
            <img v-if="userInfo.promotion_group_name === '钻石推广'" src="../../assets/images/lev4.png" class="levIcon"/><span>{{ userInfo.promotion_group_name }}</span>
          </h3>
          <h3><span>我的推广收益：</span><span>{{ balanceInfo.promotion_profit }}</span><el-button type="text" style="margin-left: 10px;" @click="toSeeDetail()">查看明细</el-button></h3>
        </div>
        <div class="levContentWrap">
          <div class="levDj">
            <img src="../../assets/images/lev1.png" class="levIcon"/>
            <span>普通推广员：</span>
          </div>
          <div class="levContent">
            <span>邀请人注册即可</span>
            <span>获取邀请人<span class="red">每单*0.05</span>返利</span>
          </div>
        </div>
        <div class="line"></div>
        <div class="levContentWrap lev2margin">
          <div class="levDj">
            <img src="../../assets/images/lev2.png" class="levIcon"/>
            <span>银牌推广员：</span>
          </div>
          <div class="levContent">
            <span>邀请注册且下单人数达到 11人</span>
            <span>获取邀请人<span class="red">每单*0.05</span>返利 + <span class="red">充值金额 *2%</span> 返利 </span>
          </div>
        </div>
        <div class="line"></div>
        <div class="levContentWrap lev2margin">
          <div class="levDj">
            <img src="../../assets/images/lev3.png" class="levIcon"/>
            <span>金牌推广员：</span>
          </div>
          <div class="levContent">
            <span>邀请注册且下单人数达到 31人</span>
            <span>获取邀请人<span class="red">每单*0.05</span>返利 + <span class="red">充值金额 *5%</span> 返利 </span>
          </div>
        </div>
        <div class="line"></div>
        <div class="levContentWrap lev2margin">
          <div class="levDj">
            <img src="../../assets/images/lev4.png" class="levIcon"/>
            <span>钻石推广员：</span>
          </div>
          <div class="levContent">
            <span>邀请注册且下单人数达到 51人</span>
            <span>获取邀请人<span class="red">每单*0.05</span>返利 + <span class="red">充值金额 *8%</span> 返利 </span>
          </div>
        </div>
        <div class="line"></div>
        <div class="levContentWrap lev2margin">
          <div class="levAtten">
            系统每半个月自动统计一次推广员的活跃邀请用户。对推广员的等级进行自动更新！
          </div>
        </div>
        <el-button type="success" class="inviteButn" @click="toInvite()">立即去推广</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from 'components/navbar/NavBar'
import { getUserMoney, moneyInfo } from 'network/api'
export default {
  components: {
    NavBar
  },
  data () {
    return {
      userInfo: {},
      memberListBase: {
        la: '功能特权',
        lb: '优惠金额',
        lc: '免费升级',
        ld: '专属客服',
        le: '专属运营导师',
        lf: '投诉建议',
        lg: '新功能内侧'
      },
      memberList: [],
      balanceInfo: {
        promotion_profit: 0
      }
    }
  },
  computed: {
    // 获取用户信息
    username () {
      return this.$store.getters.getUserInfo.username
    }
  },
  mounted () {
    this.getMemberinfo()
  },
  methods: {
    getMemberinfo () {
      getUserMoney().then((res) => {
        this.userInfo = res.data
      })
      // 获取余额信息
      moneyInfo().then(res => {
        this.balanceInfo = res.data
      })
    },
    toSeeDetail () {
      this.$router.push('/account/tgIncome')
    },
    toInvite () {
      this.$router.push('/account/InviteFriends')
    }
  }
}
</script>

<style scoped>
.prowrap{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}
.promain{
  width: 1200px;
  height: 1000px;
  background: url('../../assets/images/promotion.jpg') center no-repeat;
  background-size: 100% 100%;
}
.red{
  color: red;
}
.myInfo{
  margin-left: 180px;
  margin-top: 50px;
  color: #333;
  display: flex;
}
.myInfo h3{
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}
.levIcon{
  width: 30px;
  height: 30px;
  /* margin: 0 5px; */
  display: block;
}
.levContentWrap{
  margin-left: 240px;
  margin-top: 120px;
  width: 600px;
  display: flex;
  align-items: center;
  transform: scale(1.2);
}
.levDj{
  display: flex;
  align-items: center;
  width: 140px;
}
.levContent{
  display: flex;
  flex-direction: column;
}
.line{
  width: 600px;
  height: 2px;
  background: #efefef;
  margin-left: 180px;
  margin-top: 30px;
}
.lev2margin{
  margin-top: 30px;
}
.levAtten{
  color: #f66;
}
.inviteButn{
  width: 300px;
  margin-left: 300px;
  margin-top: 30px;
}
</style>
